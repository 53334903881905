import React from 'react';
import './App.css';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('UA-132813130-1');
  ReactGA.pageview('/');
  return (
    <div className="App">
      <header className="App-header">
        <div className="directives">
          <h3 className="text medieval">Angel Juarez</h3>
          <h3 className="text medieval">Est. 1998</h3>
        </div>

        <h1 className="text title">Full Stack Web Developer</h1>
        <h2 className="text cursive">A Node.js, React, Java, and Spring user...</h2>
        <img className="image" src="http://placekitten.com/1800/560" alt="a cat. Used for filler content." />
      </header>

      <main>
        <section>
          <h3 className="text medieval line-below">Projects</h3>
          <article className="line-below">
            <h4 className="text">News From The 6</h4>
            <p className="descriptions">
              In an age where technology helps us exchange information as fast as our internet connection. There has to be some mediation.
              This website is an exclusive news site where you won't get useless articles or surveys of which artichoke you may be.
              Only fact checked articles will be posted up here. We have exactly 0 journalists writing stories for this site so you <b>KNOW</b> you're only getting true information.
            </p>
            <div className="btn-group" role="group" aria-label="links to code or example">
              <a href="https://github.com/angeljuarez77/news-from-the-6" className="btn btn-info">Check Code</a>
              <a href="http://newsfrom-the6.surge.sh" className="btn btn-primary">See Website</a>
            </div>
          </article>
          <article className="line-below">
            <h4 className="text">Foody</h4>
            <p className="descriptions">
              Have you ever wanted a Facebook account? But only to share recipes? Well you're in luck!
              With the new Foody app you're able to share your favorite recipes with your friends and family.
              Just copy a portion of a YouTube link and submit a form then the rest of the world will be able to see your favorite recipes.
            </p>
            <div className="btn-group" role="group" aria-label="links to code or example">
              <a href="https://github.com/angeljuarez77/foody_app/" className="btn btn-info">Check Code</a>
              <a href="http://curious-soda.surge.sh" className="btn btn-primary">See Website</a>
            </div>
          </article>
          <article className="line-below">
            <h4 className="text">Stock Market App</h4>
            <p className="descriptions">
              A real time web application where you are able to see the current stock market trends.
              You are able to search up any company you want as long as you kow their stock symbol.
              <br/><br/>
              <b>Note: This is not intended for use to buy or sell stocks. It is only a novelty website.
              If you decide to trade stocks with the information in this site then all good or bad things are solely your responsibility.</b>
            </p>
            <div className="btn-group" role="group" aria-label="links to code or example">
              <a href="https://github.com/angeljuarez77/stock-market-webpage" className="btn btn-info">Check Code</a>
              <a href="http://smooth-babies.surge.sh" className="btn btn-primary">See Website</a>
            </div>
          </article>
          <article className="line-below">
            <h4 className="text">Minesweeper</h4>
            <p className="descriptions">
              This is a web app where you could play Minesweeper. Try not to lose...
              <br/><br/>
              your patience because this was my first project. And I can't seem to work on my code without deleting 1000 lines.
              So I'm thinking of just restarting. I have come a long way.
              <br/><br/>
              <q>
                We’re programmers. Programmers are, in their hearts, architects, and the first thing they want to do when they get to a site is to bulldoze the place flat and build something grand. We’re not excited by incremental renovation: tinkering, improving, planting flower beds.
              </q> -- Joel Spolsky
            </p>
            <div className="btn-group" role="group" aria-label="links to code or example">
              <a href="https://github.com/angeljuarez77/Minesweeper" className="btn btn-info">Check Code</a>
              <a href="http://shallow-club.surge.sh" className="btn btn-primary">See Website</a>
            </div>
          </article>
        </section>

        <section>
          <h3 className="text medieval line-below">About Me</h3>
          <p className="text descriptions line-below">
            I am a full stack web developer that has started their journey by being accepted into a 5
            week program from Per Scholas where I learned HTML, and CSS and the basics of JS. Afterwards based on my performance
            in Per Scholas I was accepted into the Web Development Immersive course offered by General Assembly where I learned React, Express, PostgeSQL, and Node.js.
            <br/><br/>
            I then worked as a technical instructor with Jupiter Leo Productions where I taught elders the basics of computer usage
            and I taught middle schoolers how to code in HTML, CSS and, JS to create their own personal websites.
            <br/><br/>
            While working as a part time techincal instructor I also began working with a startup as a
            fullstack developer mainly working with React, Node.js, GraphQL, and Apollo.
            <br/><br/>
            I am also currently working with General Assembly as an Instructional Associate for their
            Cognizant Java Developer Academy. In this course we taught the students how to use Java, Spring, Spring Boot, Docker, Jenkins, and Digital Cloud Foundry amongst other things.
            My duty was to assist the instructional leads making sure that all the lessons went well and to make sure that all
            the students were up to speed with the workload that was being given. This includes directing them to documentation,
            helping them debug their projects, and assisting them with their homework.
          </p>
        </section>
      </main>
    </div>
  );
}

export default App;
